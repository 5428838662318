.item {
  margin: 1rem 0;
  background-color: #575757;
  padding: 1rem;
}

.item h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1.75rem;
}

.item header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quantity span {
  font-size: 1.5rem;
  font-weight: bold;
}

.price {
  font-size: 1.5rem;
  font-weight: bold;
}

.itemprice {
  font-weight: normal;
  font-size: 1rem;
  font-style: italic;
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem 0;
}

.actions button {
  background-color: transparent;
  border: 1px solid white;
  margin-left: 0.5rem;
  padding: 0.15rem 1rem;
  color: white;
}

.actions button:hover,
.actions button:active {
  background-color: #4b4b4b;
  color: white;
}