.cart {
  max-width: 30rem;
  background-color: #313131;
  color: white;
}

.cart h2 {
  font-size: 1.25rem;
  margin: 0.5rem 0;
}

.cart ul {
  list-style: none;
  padding: 0;
  margin: 0;
}